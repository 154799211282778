.page-inspections {
    position: relative;

    &__object {
        position: absolute;
        pointer-events: none;
        z-index: -1;
        max-width: 200vw;

        &--grid {
            top: 270px;
            left: 51px;
            width: 496px;
        }

        &--building {
            top: 177px;
            right: -238px;
            width: 945px;
        }

        &--airplane {
            top: 1041px;
            left: -52px;
            width: 900px;

            @include is-screen-max($breakpoint-sm) {
                left: 30%;
                transform: translateX(-50%);
                width: 700px;
            }
        }

        &--engine {
            top: 2654px;
            right: -55px;
            width: 352px;
            animation: rotate 6s linear;
            animation-iteration-count: infinite;

            @include is-screen-max($breakpoint-sm) {
                top: 1900px;
                width: 240px;
            }
        }

        &--engine-grid {
            top: 2618px;
            right: -140px;
            width: 700px;
        }
    }

    .text-columns {
        margin-bottom: 100px;

        @include is-screen-max($breakpoint-sm) {
            margin-bottom: 80px;
        }

        &__column {
            &:nth-child(1) {
                @include is-screen-min($breakpoint-lg) {
                    padding-right: 32px;
                }
            }

            &:nth-child(2) {
                padding-top: 42px;
                transform: none;

                @include is-screen-max($breakpoint-md) {
                    padding-top: 0;
                }
            }
        }
    }

    .titlearea {
        &__text {
            width: 400px;
            margin-top: 20px;

            @include is-screen-max($breakpoint-sm) {
                width: 100%;
            }
        }
    }

    .person-block {
        width: 220px;
        max-width: 100%;
        margin-top: 44px;
        margin-left: -24px;

        @include is-screen-max($breakpoint-lg) {
            margin-left: auto;
        }

        @include is-screen-max($breakpoint-sm) {
            width: 200px;
            margin-top: 30px;
            margin-right: auto;
        }

        & > img {
            margin-bottom: 25px;

            @include is-screen-max($breakpoint-sm) {
                margin-bottom: 15px;
            }
        }

        &__title {
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;
            color: #d9d9d9;

            @include is-screen-max($breakpoint-sm) {
                font-size: 14px;
            }
        }

        &__subtitle {
            font-size: 16px;
            line-height: 140%;
            color: #d9d9d9;

            @include is-screen-max($breakpoint-sm) {
                font-size: 14px;
            }
        }

        &__icons {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;
            margin-top: 10px;

            img {
                height: 20px;
            }
        }
    }

    &__icon-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 55px;
        margin-top: 220px;
        margin-bottom: 150px;

        @include is-screen-max($breakpoint-md) {
            margin-top: 150px;
        }

        @include is-screen-max($breakpoint-sm) {
            gap: 30px;
            margin-top: 100px;
            margin-bottom: 100px;
        }

        & > p {
            width: 850px;
            max-width: 100%;
            font-family: "Technor", sans-serif;
            font-weight: 500;
            font-size: 48px;
            line-height: 100%;
            letter-spacing: -0.66px;
            color: #ffffff;
            text-align: center;
            text-transform: uppercase;

            @include is-screen-max($breakpoint-md) {
                font-size: 40px;
            }

            @include is-screen-max($breakpoint-sm) {
                font-size: 27px;
            }

            span {
                color: #dcebc1;
            }
        }

        & > .row {
            justify-content: center;
            row-gap: 30px;
        }

        .icon-block {
            flex-direction: column;

            &__img {
                width: 65px;
                height: 50px;

                @include is-screen-max($breakpoint-sm) {
                    width: 50px;
                    height: 40px;
                }
            }

            &__body {
                width: 290px;
                max-width: 100%;
            }

            &__title {
                font-family: "Technor", sans-serif;
                font-size: 20px;
                line-height: 120%;
                color: #fefefe;
                text-align: center;

                @include is-screen-max($breakpoint-sm) {
                    font-size: 16px;
                }
            }

            &__subtitle {
                margin-top: 6px;
                font-family: "Share", sans-serif;
                font-size: 16px;
                line-height: 140%;
                color: #d9d9d9;
                text-transform: initial;
                text-align: center;

                @include is-screen-max($breakpoint-sm) {
                    font-size: 14px;
                }
            }
        }
    }

    &__table-cards {
        margin-top: 60px;
        margin-bottom: 250px;

        @include is-screen-max($breakpoint-md) {
            margin-top: 40px;
            margin-bottom: 180px;
        }

        @include is-screen-max($breakpoint-sm) {
            margin-top: 20px;
            margin-bottom: 50px;
        }
    }

    &__text-cards {
        margin-top: 50px;

        @include is-screen-max($breakpoint-sm) {
            margin-top: 30px;
        }

        .text-card {
            height: 100%;
            margin-bottom: 28px;

            @include is-screen-max($breakpoint-sm) {
                margin-bottom: 20px;
            }
        }
    }

    .cta-banner {
        margin-top: -10px;

        @include is-screen-max($breakpoint-sm) {
            margin-top: 0;
        }
    }
}
