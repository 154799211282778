.block-card {
    width: 670px;
    max-width: 100%;
    padding: 30px;
    border: 1px solid rgba(255, 255, 255, 0.24);
    border-radius: 8px;

    @include is-screen-max($breakpoint-sm) {
        align-self: center;
        width: 560px;
        padding: 20px;
        border-radius: 6px;
    }

    &__title {
        font-family: "Technor", sans-serif;
        font-weight: 500;
        font-size: 48px;
        line-height: 100%;
        letter-spacing: -0.66px;
        color: #fefefe;
        text-transform: uppercase;

        @include is-screen-max($breakpoint-sm) {
            font-size: 32px;
        }
    }

    &__body {
        display: flex;
        justify-content: space-between;
        gap: 30px;

        @include is-screen-max($breakpoint-sm) {
            align-items: center;
            gap: 20px;
        }

        @include is-screen-max($breakpoint-xs) {
            flex-direction: column;
            align-items: flex-end;
            gap: 10px;
        }
    }

    &__text {
        width: 330px;
        max-width: 100%;
        margin-top: 50px;
        font-size: 16px;
        line-height: 140%;
        color: #d9d9d9;

        @include is-screen-max($breakpoint-sm) {
            margin-top: 0;
            font-size: 14px;
        }

        @include is-screen-max($breakpoint-xs) {
            width: 100%;
            margin-top: 10px;
        }
    }

    .card-time {
        flex-shrink: 0;
        min-width: 210px;

        @include is-screen-max($breakpoint-sm) {
            min-width: 150px;
        }

        &__value {
            font-family: "Advent Pro", sans-serif;
            font-weight: 600;
            font-size: 200px;
            line-height: 89%;
            color: #dcebc1;
            text-align: center;

            @include is-screen-max($breakpoint-sm) {
                font-size: 140px;
            }
        }

        &__text {
            margin-top: 6px;
            font-weight: 700;
            font-size: 21px;
            line-height: 83%;
            color: #dcebc1;
            text-transform: uppercase;
            text-align: center;

            @include is-screen-max($breakpoint-sm) {
                margin-top: 4px;
                font-size: 16px;
            }
        }
    }
}
