.ruler-wrap {
    position: relative;
    padding-bottom: 200px;

    @include is-screen-max($breakpoint-sm) {
        padding-bottom: 100px;
    }

    &__track {
        position: absolute;
        left: 80px;
        top: 18px;
        bottom: 0;
        width: 40px;
        pointer-events: none;
        overflow: hidden;
        z-index: 10;

        img {
            width: 100%;
            transform: translateY(-50%);
            transition: transform 0.1s linear;
        }

        @include is-screen-max($breakpoint-md) {
            display: none;
        }
    }
}
