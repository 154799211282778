/**********************************************************************/
/**********************************************************************/
body {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
    margin: 0 auto;
    background: linear-gradient(298.14deg, #101a29 18.19%, #000000 90.82%);
    font: normal 400 16px / 140% "Share", sans-serif;
    color: #d9d9d9;
    cursor: default;

    &.no-scroll {
        overflow: hidden;
    }

    * {
        box-sizing: border-box;
        margin: 0;
    }

    img,
    svg {
        display: block;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }

    a {
        display: inline-block;
        text-decoration: none;
    }

    /******************************************************************/
    .disabled,
    .disabled * {
        pointer-events: none;
        cursor: not-allowed;
    }

    /******************************************************************/
    .hide {
        display: none !important;

        @include is-screen-min($breakpoint-sm) {
            &-desk {
                display: none !important;
            }
        }

        @include is-screen-max($breakpoint-sm) {
            &-mob {
                display: none !important;
            }
        }
    }

    /******************************************************************/
    .scrollbar {
        overflow: auto;

        &::-webkit-scrollbar {
            height: 0;
            width: 2px;

            &-track {
                background: transparent;
            }

            &-button {
                display: none;
            }

            &-thumb {
                background-color: #0cc166;
            }
        }
    }
}

/**********************************************************************/
/**********************************************************************/
.container {
    display: flex;
    flex-direction: column;

    &__content {
        width: 1240px;
        max-width: calc(100% - 200px);
        margin-left: auto;
        margin-right: auto;

        @include is-screen-max($breakpoint-md) {
            max-width: calc(100% - 60px);
        }

        @include is-screen-max($breakpoint-sm) {
            max-width: calc(100% - 40px);
        }
    }
}

/**********************************************************************/
/**********************************************************************/
.row {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 24px);
    margin-left: -12px;
    margin-right: -12px;
}

/**********************************************************************/
/**********************************************************************/
.col {
    display: flex;
    flex-direction: column;
    padding-left: 12px;
    padding-right: 12px;

    &-0 {
        padding: 0;
    }

    @for $i from 0 through 12 {
        &-#{$i} {
            width: calc(100% / 12 * $i);
        }
    }

    $sizes: "xl" $breakpoint-xl, "lg" $breakpoint-lg, "md" $breakpoint-md,
        "sm" $breakpoint-sm, "xs" $breakpoint-xs;

    @each $size, $breakpoint in $sizes {
        @include is-screen-max($breakpoint) {
            &-#{$size}-0 {
                padding: 0;
            }

            @for $i from 0 through 12 {
                &-#{$size}-#{$i} {
                    width: calc(100% / 12 * $i);
                }
            }
        }
    }
}

/**********************************************************************/
/**********************************************************************/
.main {
    position: relative;
    padding-top: 80px;
    overflow: hidden;

    @include is-screen-max($breakpoint-sm) {
        padding-top: 60px;
    }

    &::before {
        content: "";
        position: absolute;
        left: 69px;
        top: 0;
        bottom: 0;
        width: 1px;
        background-color: rgba(255, 255, 255, 0.25);

        @include is-screen-max($breakpoint-md) {
            display: none;
        }
    }
}

/**********************************************************************/
/**********************************************************************/
@import "header";
@import "footer";
@import "form";
@import "popup";
