@mixin is-screen-min($size) {
    @media screen and (min-width: $size + 1) {
        @content;
    }
}

@mixin is-screen-max($size) {
    @media screen and (max-width: $size) {
        @content;
    }
}
