.formarea {
    input,
    textarea {
        outline: none;
        appearance: none;
    }

    &__line {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;

        @include is-screen-max($breakpoint-sm) {
            margin-bottom: 16px;
        }

        input,
        textarea {
            display: block;
            width: 100%;
            padding: 19px 19px 17px;
            border: 1px solid #d9d9d9;
            border-radius: 6px;
            background-color: transparent;
            font-family: "Share", sans-serif;
            font-weight: 400;
            font-size: 18px;
            line-height: 120%;
            color: #ffffff;
            outline: none;
            transition: border-color 0.3s, color 0.3s;

            &::placeholder {
                color: #808080;
            }

            &:focus,
            &:not(.is-empty) {
                & ~ .formarea__label {
                    opacity: 0;
                }
            }

            &.error {
                border-color: #aa2121;
                color: #aa2121;

                &::placeholder,
                & ~ .formarea__label,
                & ~ .formarea__label span {
                    color: #aa2121;
                }
            }

            @include is-screen-max($breakpoint-sm) {
                padding: 12px 16px;
                border-radius: 4px;
                font-size: 16px;
            }
        }

        textarea {
            height: 172px;
            resize: none;

            @include is-screen-max($breakpoint-sm) {
                height: 120px;
            }
        }
    }

    &__label {
        position: absolute;
        left: 20px;
        top: 20px;
        font-size: 18px;
        line-height: 120%;
        color: #808080;
        transition: opacity 0.3s, color 0.3s;

        @include is-screen-max($breakpoint-sm) {
            left: 17px;
            top: 13px;
            font-size: 16px;
        }

        span {
            color: #0cc166;
        }
    }

    &__submit {
        display: flex;
        justify-content: flex-start;

        input {
            width: 220px;
            max-width: 100%;
            padding: 20px;
            border: none;
            border-radius: 6px;
            background-color: #ffffff;
            font-family: "Technor", sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.14px;
            color: #0a111a;
            text-transform: uppercase;
            text-align: center;
            transition: opacity 0.3s;
            cursor: pointer;

            &:hover {
                opacity: 0.6;
            }
        }

        @include is-screen-max($breakpoint-sm) {
            justify-content: center;

            input {
                width: 160px;
                padding: 10px;
                border-radius: 4px;
                font-size: 14px;
            }
        }
    }

    label.error {
        display: none !important;
    }

    &__response {
        font-size: 16px;
        line-height: 20px;
        color: #7ebb00;

        &.error {
            color: #aa2121;
        }

        @include is-screen-max($breakpoint-sm) {
            font-size: 14px;
            text-align: center;
        }
    }
}
