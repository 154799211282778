.page-engines {
    position: relative;

    &__object {
        position: absolute;
        pointer-events: none;
        z-index: -1;

        &--turbine {
            top: 74px;
            right: -254px;
            width: 888px;
            max-width: initial;
        }

        &--engine {
            top: 502px;
            right: -98px;
            width: 338px;
            transform: rotate(180deg);
        }

        &--engine-grid {
            top: 1523px;
            right: -395px;
            width: 854px;
        }

        &--grid {
            top: 270px;
            left: 51px;
            width: 496px;
        }

        &--gear-grid {
            top: 2383px;
            left: 10px;
            width: 621px;
        }

        &--gear {
            top: 2488px;
            left: 10px;
            width: 350px;
            animation: rotate 9s linear;
            animation-iteration-count: infinite;

            @include is-screen-max($breakpoint-md) {
                top: 2200px;
                width: 300px;
            }

            @include is-screen-max($breakpoint-sm) {
                top: 1900px;
            }
        }
    }

    .text-large {
        margin-top: 30px;
        margin-bottom: 145px;
        font-family: "Technor", sans-serif;
        font-weight: 500;
        font-size: 48px;
        line-height: 100%;
        letter-spacing: -0.66px;
        color: #fefefe;
        text-transform: uppercase;

        @include is-screen-max($breakpoint-md) {
            font-size: 36px;
        }

        @include is-screen-max($breakpoint-sm) {
            margin-top: 15px;
            margin-bottom: 100px;
            font-size: 24px;
            line-height: 110%;
        }

        span {
            color: #dcebc1;
        }
    }

    &__table-cards {
        margin-top: 60px;
        margin-bottom: 245px;

        @include is-screen-max($breakpoint-md) {
            margin-top: 40px;
            margin-bottom: 180px;
        }

        @include is-screen-max($breakpoint-sm) {
            margin-top: 20px;
            margin-bottom: 70px;
        }
    }

    &__note {
        width: 860px;
        max-width: 100%;
        margin: 40px auto 0;
        border: 1px solid #0cc166;
        border-radius: 8px;

        @include is-screen-max($breakpoint-sm) {
            margin-top: 0;
        }

        p {
            padding: 16px 32px;
            font-family: "Technor", sans-serif;
            font-weight: 500;
            font-size: 26px;
            line-height: 100%;
            letter-spacing: -0.66px;
            color: #fefefe;
            text-align: center;
            text-transform: uppercase;

            @include is-screen-max($breakpoint-sm) {
                font-size: 20px;
                line-height: 120%;
            }
        }
    }

    .cta-banner {
        margin-top: 85px;

        @include is-screen-max($breakpoint-sm) {
            margin-top: 0;
        }
    }
}
