.page-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 17px;
    padding-top: 155px;
    padding-bottom: 285px;

    @include is-screen-max($breakpoint-md) {
        padding-top: 150px;
        padding-bottom: 200px;
    }

    @include is-screen-max($breakpoint-sm) {
        gap: 10px;
        padding-top: 120px;
        padding-bottom: 120px;
    }

    &__back {
        display: flex;
        align-items: center;
        gap: 12px;
        transition: opacity 0.3s;

        &:hover {
            opacity: 0.6;
        }

        img {
            width: 6px;
        }

        p {
            font-size: 26px;
            line-height: 140%;
            color: #d9d9d9;

            @include is-screen-max($breakpoint-sm) {
                font-size: 16px;
            }
        }
    }

    &__title {
        font-family: "Technor", sans-serif;
        font-weight: 500;
        font-size: 68px;
        line-height: 100%;
        color: #ffffff;
        text-transform: uppercase;
        text-align: center;

        @include is-screen-max($breakpoint-sm) {
            font-size: 35px;
        }

        span {
            color: #dcebc1;
        }
    }
}
