.page-about {
    position: relative;

    &__object {
        position: absolute;
        pointer-events: none;
        z-index: -1;

        &--airplane {
            top: 110px;
            left: calc(50% + 53px);
            width: 1114px;

            @include is-screen-max($breakpoint-md) {
                top: 60px;
                left: 85%;
                transform: translateX(-50%);
                width: 800px;
                max-width: initial;
            }
        }

        &--grid {
            top: 270px;
            left: 51px;
            width: 496px;
        }
    }

    &__map {
        position: absolute;
        top: 938px;
        left: calc(50% - 10px);

        @include is-screen-max($breakpoint-lg) {
            left: 40%;
        }

        @include is-screen-max($breakpoint-md) {
            left: initial;
            right: -5%;
        }

        @include is-screen-max($breakpoint-sm) {
            top: 600px;
            right: -8%;
        }

        @include is-screen-max(550px) {
            top: 700px;
        }

        @include is-screen-max($breakpoint-xs) {
            position: relative;
            top: initial;
            right: initial;
            left: 50%;
            transform: translateX(-50%);
            margin-top: 50px;
        }
    }

    .block-card {
        width: auto;
        margin-bottom: 156px;
        padding: 0;
        border: none;
        border-radius: 0;

        @include is-screen-max($breakpoint-sm) {
            margin-bottom: 100px;
        }

        &__body {
            justify-content: flex-start;
            gap: 0;

            @include is-screen-max($breakpoint-xs) {
                align-items: center;
                gap: 20px;
            }
        }

        &__text {
            width: 100%;
            margin-top: 0;
        }

        .card-time {
            margin-top: 40px;

            @include is-screen-max($breakpoint-md) {
                align-self: flex-end;
                margin-top: 0;
            }
        }
    }

    &__icon-list {
        display: flex;
        flex-direction: column;
        gap: 60px;
        margin-top: 75px;
        margin-bottom: 186px;

        @include is-screen-max($breakpoint-sm) {
            gap: 30px;
            margin-top: 30px;
            margin-bottom: 80px;
        }

        .icon-block {
            &:last-child {
                margin-left: 20px;

                @include is-screen-max($breakpoint-md) {
                    margin-left: 0;
                }

                @include is-screen-max($breakpoint-xs) {
                    margin-top: 30px;
                }
            }
        }
    }

    .cta-banner {
        margin-top: 92px;

        @include is-screen-max(550px) {
            margin-top: 150px;
        }

        @include is-screen-max($breakpoint-xs) {
            margin-top: -50px;
        }
    }
}
