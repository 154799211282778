.page-home {
    position: relative;

    &__object {
        position: absolute;
        pointer-events: none;
        z-index: -1;

        &--gear {
            top: 120px;
            right: -128px;
            width: 350px;
            animation: rotate 9s linear;
            animation-iteration-count: infinite;
        }

        &--turbine {
            top: 500px;
            right: -60px;
            width: 449px;
            transform: rotate(-4.22deg);
        }

        &--logo {
            top: 758px;
            left: -77px;
            width: 365px;
        }

        &--grid {
            top: 1643px;
            right: 0;
            width: 1114px;
        }

        &--helecopter {
            top: 1441px;
            right: -136px;
            width: 868px;
            mix-blend-mode: screen;
            opacity: 0.6;
        }

        &--airplane {
            top: 2288px;
            right: -190px;
            width: 835px;
            transform: rotate(-60deg);
        }
    }

    .home-banner {
        margin-top: 8px;
        margin-bottom: 60px;
        padding-top: 140px;
        padding-bottom: 114px;

        @include is-screen-max($breakpoint-sm) {
            margin-top: 0;
            margin-bottom: 20px;
            padding-top: 60px;
            padding-bottom: 40px;
        }

        &__title {
            margin-bottom: 30px;
            font-family: "Technor", sans-serif;
            font-weight: 500;
            font-size: 68px;
            line-height: 100%;
            color: #ffffff;
            text-transform: uppercase;

            @include is-screen-max($breakpoint-sm) {
                margin-bottom: 20px;
                font-size: 40px;
            }

            span {
                color: rgba(220, 235, 193, 1);
            }
        }

        &__subtitle {
            width: 500px;
            max-width: 100%;
            font-size: 16px;
            line-height: 140%;
            color: #d9d9d9;

            @include is-screen-max($breakpoint-sm) {
                font-size: 14px;
            }
        }
    }

    .marquee {
        height: 200px;
        margin-top: 70px;
        margin-bottom: 110px;

        @include is-screen-max($breakpoint-sm) {
            height: 80px;
            margin-top: 30px;
            margin-bottom: 30px;
        }

        &__txt {
            margin-right: 120px;
            font-family: "Technor", sans-serif;
            font-weight: 900;
            font-size: 200px;
            line-height: 100%;
            letter-spacing: 6px;
            color: #1e2228;
            text-transform: uppercase;
            -webkit-text-stroke: 1px #000000;
            -webkit-text-fill-color: #1e2228;

            @include is-screen-max($breakpoint-sm) {
                margin-right: 30px;
                font-size: 80px;
                letter-spacing: 2px;
            }
        }
    }

    &__lists {
        display: flex;
        flex-direction: column;
        gap: 200px;
        margin-top: 192px;
        margin-bottom: 200px;

        @include is-screen-max($breakpoint-sm) {
            gap: 50px;
            margin-top: 50px;
            margin-bottom: 50px;
        }

        .home-list {
            position: relative;

            &__title {
                position: absolute;
                top: 5px;
                left: -20px;
                transform: translate(-100%, -100%) rotate(-90deg);
                transform-origin: right bottom;
                font-family: "Technor", sans-serif;
                font-weight: 500;
                font-size: 26px;
                line-height: 100%;
                letter-spacing: 2px;
                color: #dcebc1;
                text-transform: uppercase;

                @include is-screen-max($breakpoint-sm) {
                    top: 5px;
                    left: 32px;
                    font-size: 20px;
                    letter-spacing: 1px;
                }
            }

            &__values {
                display: flex;
                flex-direction: column;
                gap: 6px;
                padding-left: 20px;
                list-style: square;
                font-size: 16px;
                line-height: 140%;
                color: #d9d9d9;

                @include is-screen-max($breakpoint-sm) {
                    gap: 4px;
                    padding-left: 50px;
                    font-size: 14px;
                }
            }
        }
    }
}
