.icon-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;

    &__img {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 70px;
        height: 65px;

        @include is-screen-max($breakpoint-sm) {
            width: 60px;
            height: 50px;
        }
    }

    &__title {
        font-family: "Advent Pro", sans-serif;
        font-weight: 400;
        font-size: 54px;
        line-height: 91%;
        color: #d9d9d9;
        text-transform: uppercase;

        @include is-screen-max($breakpoint-sm) {
            font-size: 40px;
            line-height: 100%;
        }
    }

    &__subtitle {
        font-family: "Technor", sans-serif;
        font-weight: 400;
        font-size: 20px;
        line-height: 120%;
        color: #fefefe;
        text-transform: uppercase;

        @include is-screen-max($breakpoint-sm) {
            font-size: 14px;
            line-height: 110%;
        }
    }
}
