.header {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80px;
    width: 100vw;
    max-width: inherit;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    background-color: #000000;
    z-index: 60;

    @include is-screen-max($breakpoint-sm) {
        height: 60px;
    }

    &__side {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70px;
        border-right: 1px solid rgba(255, 255, 255, 0.25);

        @include is-screen-max($breakpoint-md) {
            display: none;
        }

        img {
            width: 25px;
        }
    }

    &__content {
        display: flex;
        align-items: center;
    }

    &__logo {
        height: 20px;
        margin-right: auto;
        transition: opacity 0.3s;

        @include is-screen-max($breakpoint-sm) {
            height: 17px;
        }

        @include is-screen-max($breakpoint-xs) {
            height: 30px;
        }

        &:hover {
            opacity: 0.6;
        }
    }

    &__menu {
        margin-left: 60px;
        margin-right: 100px;

        @include is-screen-max($breakpoint-lg) {
            margin-left: 30px;
            margin-right: 60px;
        }

        @include is-screen-max($breakpoint-md) {
            position: absolute;
            left: 0;
            top: 80px;
            transform: translateX(-100%);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100vw;
            height: calc(100vh - 80px);
            margin: 0;
            padding: 40px;
            background: linear-gradient(
                298.14deg,
                #101a29 18.19%,
                #000000 90.82%
            );
            pointer-events: none;
            overflow: hidden;
            transition: transform 0.3s;
            z-index: 80;
        }

        @include is-screen-max($breakpoint-sm) {
            top: 60px;
            height: calc(100vh - 60px);
        }

        .menu__list {
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
            gap: 5px 30px;
            padding: 0;
            list-style: none;

            @include is-screen-max($breakpoint-md) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 20px;
            }

            .menu-item {
                a {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 25px;
                    letter-spacing: 1.17px;
                    color: #ffffff;
                    text-align: center;
                    text-transform: uppercase;
                    transition: opacity 0.3s;

                    &:hover {
                        opacity: 0.6;
                    }
                }
            }
        }
    }

    &__button {
        position: relative;
        display: flex;
        flex-direction: column;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            border-radius: 6px;
            background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 0.21) 50%,
                rgba(255, 255, 255, 1) 100%
            );
        }

        span {
            position: relative;
            margin: 1px;
            padding: 13px 33px;
            border-radius: 6px;
            background-color: #000000;
            font-family: "Technor", sans-serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: 0.14px;
            color: #ffffff;
            text-transform: uppercase;
            text-align: center;
            white-space: nowrap;
            transition: background-color 0.3s, color 0.3s;

            @include is-screen-max($breakpoint-sm) {
                padding: 9px 18px;
                font-size: 12px;
            }
        }

        &:hover {
            span {
                background-color: #ffffff;
                color: #000000;
            }
        }
    }

    &__burger {
        position: relative;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        margin: auto 20px auto 0;
        cursor: pointer;

        @include is-screen-max($breakpoint-md) {
            display: flex;
        }

        @include is-screen-max($breakpoint-sm) {
            width: 24px;
            height: 24px;
            margin-right: 15px;
        }

        span {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 75%;
            height: 2px;
            background-color: rgba(255, 255, 255, 0.75);
            transform-origin: center;
            transition: transform 0.3s, opacity 0.3s;

            &:nth-child(1) {
                transform: translate(-50%, calc(-50% - 8px));

                @include is-screen-max($breakpoint-sm) {
                    transform: translate(-50%, calc(-50% - 6px));
                }
            }

            &:nth-child(3) {
                transform: translate(-50%, calc(-50% + 8px));

                @include is-screen-max($breakpoint-sm) {
                    transform: translate(-50%, calc(-50% + 6px));
                }
            }
        }
    }

    &.show-menu {
        .header {
            &__burger {
                span {
                    &:nth-child(1) {
                        transform: translate(-50%, -50%) rotate(45deg);
                    }

                    &:nth-child(2) {
                        opacity: 0;
                    }

                    &:nth-child(3) {
                        transform: translate(-50%, -50%) rotate(-45deg);
                    }
                }
            }

            &__menu {
                transform: none;
                pointer-events: initial;
            }
        }
    }
}
