.map {
    position: relative;
    width: 682px;
    pointer-events: none;
    z-index: -1;

    @include is-screen-max($breakpoint-sm) {
        width: 500px;
    }

    @include is-screen-max($breakpoint-xs) {
        width: 100vw;
    }

    &__image {
        width: 100%;
        max-width: initial;
    }

    &__locations {
        .location {
            position: absolute;
            display: flex;
            align-items: center;
            gap: 2px;
            animation-name: blink;
            animation-duration: 4s;
            transform-origin: 50% 50%;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;

            @keyframes blink {
                0% {
                    opacity: 0;
                    transform: scale(0);
                }
                20% {
                    opacity: 1;
                    transform: scale(1.05);
                }
                25% {
                    transform: scale(1);
                }
                75% {
                    transform: scale(1);
                }
                80% {
                    transform: scale(1.05);
                    opacity: 1;
                }
                100% {
                    opacity: 0;
                }
            }

            img {
                width: 8px;

                @include is-screen-max($breakpoint-sm) {
                    width: 7px;
                }

                @include is-screen-max($breakpoint-xs) {
                    width: 2vw;
                    transform: translateY(-50%);
                }
            }

            p {
                font-size: 12px;
                line-height: 14px;
                color: #ffffff;

                @include is-screen-max($breakpoint-sm) {
                    font-size: 10px;
                    line-height: 100%;
                }

                @include is-screen-max($breakpoint-xs) {
                    transform: translateY(-50%);
                    font-size: 2.5vw;
                }
            }

            &:nth-child(1) {
                top: 40%;
                left: 12.317%;
                animation-delay: 0.5s;
            }

            &:nth-child(2) {
                top: 46.7%;
                left: 23.9%;
            }

            &:nth-child(3) {
                top: 29.8%;
                left: 46.481%;
                animation-delay: 1s;
            }

            &:nth-child(4) {
                top: 27.705%;
                left: 50%;
            }

            &:nth-child(5) {
                top: 48.8%;
                left: 61.144%;
                animation-delay: 0.5s;
            }

            &:nth-child(6) {
                top: 46.7%;
                left: 61.8%;
            }

            &:nth-child(7) {
                top: 62.533%;
                left: 74.194%;
                animation-delay: 1s;
            }

            &:nth-child(8) {
                top: 65.172%;
                left: 75.513%;
            }

            &:nth-child(9) {
                top: 47.757%;
                left: 78.886%;
                animation-delay: 0.5s;
            }
        }
    }
}
