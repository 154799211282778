.table-card {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @include is-screen-max($breakpoint-sm) {
        flex-direction: row;
        align-items: center;
        gap: 15px;
        height: auto;
        margin-bottom: 30px;
    }

    @include is-screen-max($breakpoint-xs) {
        flex-direction: column;
    }

    .card-list {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        min-height: 350px;
        gap: 25px;
        padding: 45px 28px 28px;
        border: 1px solid rgba(255, 255, 255, 0.24);
        border-radius: 8px;

        @include is-screen-max($breakpoint-sm) {
            width: 250px;
            min-height: 0;
            padding: 20px;
            gap: 20px;
        }

        @include is-screen-max($breakpoint-xs) {
            width: 100%;
        }

        &__item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 5px;

            img {
                width: auto;
                max-height: 70px;
            }

            p {
                font-size: 15px;
                line-height: 140%;
                color: #d9d9d9;
                text-transform: uppercase;

                @include is-screen-max($breakpoint-sm) {
                    font-size: 14px;
                }
            }
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    &__title {
        font-family: "Technor", sans-serif;
        font-weight: 400;
        font-size: 20px;
        line-height: 120%;
        color: #fefefe;
        text-transform: uppercase;

        @include is-screen-max($breakpoint-sm) {
            font-size: 16px;
        }
    }

    &__description {
        font-size: 16px;
        line-height: 140%;
        color: #d9d9d9;

        @include is-screen-max($breakpoint-sm) {
            font-size: 14px;
        }
    }

    &--long {
        .card-list {
            gap: 15px;
            padding-top: 22px;

            &__item {
                img {
                    max-height: 60px;
                }

                &:first-child {
                    img {
                        max-height: 65px;
                    }
                }
            }
        }
    }
}
