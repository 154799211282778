.text-columns {
    justify-content: center;
    padding-bottom: 40px;

    @include is-screen-max($breakpoint-sm) {
        padding-bottom: 0;
    }

    &__column {
        display: flex;
        flex-direction: column;
        gap: 42px;
        margin-top: 54px;

        @include is-screen-max($breakpoint-sm) {
            gap: 20px;
            margin-top: 20px;
        }

        &:nth-child(2) {
            transform: translate(17px, 11px);

            @include is-screen-max($breakpoint-xl) {
                transform: none;
            }
        }

        p {
            font-size: 16px;
            line-height: 140%;
            color: #d9d9d9;

            @include is-screen-max($breakpoint-sm) {
                font-size: 14px;
            }

            span {
                color: #0cc166;
            }
        }
    }
}
