.cta-banner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding-top: 340px;
    padding-bottom: 150px;

    @include is-screen-max($breakpoint-sm) {
        padding-top: 150px;
        padding-bottom: 50px;
    }

    &__title {
        font-family: "Technor", sans-serif;
        font-weight: 500;
        font-size: 48px;
        line-height: 100%;
        color: #ffffff;
        text-transform: uppercase;
        text-align: center;

        @include is-screen-max($breakpoint-sm) {
            font-size: 30px;
        }

        span {
            color: #dcebc1;
        }
    }

    &__link {
        font-family: "Technor", sans-serif;
        font-weight: 400;
        font-size: 20px;
        line-height: 120%;
        letter-spacing: 0.16px;
        color: #fefefe;
        text-transform: uppercase;
        text-decoration-line: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 5px;
        transition: opacity 0.3s;

        @include is-screen-max($breakpoint-sm) {
            font-size: 16px;
        }

        &:hover {
            opacity: 0.6;
        }
    }

    &__object {
        position: absolute;
        pointer-events: none;
        z-index: -1;

        &--logo {
            top: 90px;
            right: calc(50% + 184px);
            width: 331px;

            @include is-screen-max($breakpoint-sm) {
                top: 50px;
                right: 70%;
                width: 280px;
            }
        }

        &--airplane {
            top: 210px;
            left: calc(50% + 50px);
            width: 858px;

            @include is-screen-max($breakpoint-sm) {
                top: 130px;
                left: 70%;
                transform: translateX(-50%);
                width: 500px;
                max-width: initial;
            }
        }
    }
}
