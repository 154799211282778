@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:wght@400;600&display=swap");

@font-face {
    font-family: "Share";
    src: url("../fonts/Share/ShareTech-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

$types: "Extralight" 200, "Light" 300, "Regular" 400, "Medium" 500,
    "Semibold" 600, "Bold" 700, "Black" 900;
@each $type, $weight in $types {
    @font-face {
        font-family: "Technor";
        src: url("../fonts/Technor/Technor-#{$type}.otf") format("opentype");
        font-weight: $weight;
        font-style: normal;
    }
}
