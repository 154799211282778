.page-contacts {
    position: relative;

    .ruler-wrap {
        margin-top: 205px;

        @include is-screen-max($breakpoint-sm) {
            margin-top: 100px;
        }

        @include is-screen-max(550px) {
            margin-top: 60px;
        }
    }

    .titlearea {
        margin-bottom: 100px;

        @include is-screen-max(550px) {
            margin-bottom: 30px;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        width: 310px;
        max-width: 100%;
        margin-top: 55px;

        @include is-screen-max($breakpoint-sm) {
            gap: 15px;
            margin-top: 30px;
        }

        .list-item {
            display: flex;
            align-items: flex-start;
            gap: 7px;

            &[href] {
                transition: opacity 0.3s;

                &:hover {
                    opacity: 0.6;
                }
            }

            &__icon {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                width: 16px;
                height: 18px;
            }

            p {
                font-size: 15px;
                line-height: 17px;
                letter-spacing: 0.16px;
                color: #d9d9d9;

                @include is-screen-max($breakpoint-sm) {
                    font-size: 14px;
                }
            }
        }
    }

    &__map {
        position: absolute;
        top: -40px;
        left: calc(50% - 10px);

        @include is-screen-max($breakpoint-lg) {
            left: 40%;
        }

        @include is-screen-max($breakpoint-md) {
            top: -80px;
            left: initial;
            right: -5%;
        }

        @include is-screen-max($breakpoint-sm) {
            right: -8%;
        }

        @include is-screen-max(550px) {
            position: relative;
            top: initial;
            right: initial;
            left: 50%;
            transform: translateX(-50%);
        }

        .map {
            &__grid {
                position: absolute;
                top: 0;
                left: 0;
                transform: translateY(-17.5%);
                width: 114.37%;
                pointer-events: none;
                z-index: -1;
            }
        }
    }
}
