@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:wght@400;600&display=swap");
@font-face {
  font-family: "Share";
  src: url("../fonts/Share/ShareTech-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Technor";
  src: url("../fonts/Technor/Technor-Extralight.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Technor";
  src: url("../fonts/Technor/Technor-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Technor";
  src: url("../fonts/Technor/Technor-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Technor";
  src: url("../fonts/Technor/Technor-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Technor";
  src: url("../fonts/Technor/Technor-Semibold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Technor";
  src: url("../fonts/Technor/Technor-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Technor";
  src: url("../fonts/Technor/Technor-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}
/**********************************************************************/
/**********************************************************************/
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
.ruler-wrap {
  position: relative;
  padding-bottom: 200px;
}
@media screen and (max-width: 767px) {
  .ruler-wrap {
    padding-bottom: 100px;
  }
}
.ruler-wrap__track {
  position: absolute;
  left: 80px;
  top: 18px;
  bottom: 0;
  width: 40px;
  pointer-events: none;
  overflow: hidden;
  z-index: 10;
}
.ruler-wrap__track img {
  width: 100%;
  transform: translateY(-50%);
  transition: transform 0.1s linear;
}
@media screen and (max-width: 1023px) {
  .ruler-wrap__track {
    display: none;
  }
}

.titlearea__line {
  position: relative;
  height: 100%;
}
.titlearea__line::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: calc(100% + 50vw - 620px - 80px);
  height: 1px;
  background-color: rgba(255, 255, 255, 0.25);
}
@media screen and (max-width: 1439px) {
  .titlearea__line::before {
    width: calc(100% + 100px - 80px);
  }
}
@media screen and (max-width: 1023px) {
  .titlearea__line::before {
    display: none;
  }
}
.titlearea__subtitle {
  font-family: "Technor", sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 130%;
  letter-spacing: 2px;
  color: #0cc166;
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .titlearea__subtitle {
    font-size: 18px;
    letter-spacing: 1px;
  }
}
.titlearea__title {
  margin-top: 16px;
  font-family: "Technor", sans-serif;
  font-weight: 500;
  font-size: 48px;
  line-height: 100%;
  letter-spacing: -0.66px;
  color: #fefefe;
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .titlearea__title {
    margin-top: 10px;
    font-size: 24px;
  }
}
.titlearea__list {
  margin-top: 32px;
  padding-left: 20px;
  font-family: "Technor", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: 0.16px;
  color: #fefefe;
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .titlearea__list {
    margin-top: 20px;
    font-size: 14px;
  }
}
.titlearea__text {
  width: 320px;
  max-width: 100%;
  margin-top: 30px;
  font-size: 16px;
  line-height: 140%;
  color: #d9d9d9;
}
@media screen and (max-width: 767px) {
  .titlearea__text {
    margin-top: 10px;
    font-size: 14px;
  }
}

.block-card {
  width: 670px;
  max-width: 100%;
  padding: 30px;
  border: 1px solid rgba(255, 255, 255, 0.24);
  border-radius: 8px;
}
@media screen and (max-width: 767px) {
  .block-card {
    align-self: center;
    width: 560px;
    padding: 20px;
    border-radius: 6px;
  }
}
.block-card__title {
  font-family: "Technor", sans-serif;
  font-weight: 500;
  font-size: 48px;
  line-height: 100%;
  letter-spacing: -0.66px;
  color: #fefefe;
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .block-card__title {
    font-size: 32px;
  }
}
.block-card__body {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
@media screen and (max-width: 767px) {
  .block-card__body {
    align-items: center;
    gap: 20px;
  }
}
@media screen and (max-width: 449px) {
  .block-card__body {
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
  }
}
.block-card__text {
  width: 330px;
  max-width: 100%;
  margin-top: 50px;
  font-size: 16px;
  line-height: 140%;
  color: #d9d9d9;
}
@media screen and (max-width: 767px) {
  .block-card__text {
    margin-top: 0;
    font-size: 14px;
  }
}
@media screen and (max-width: 449px) {
  .block-card__text {
    width: 100%;
    margin-top: 10px;
  }
}
.block-card .card-time {
  flex-shrink: 0;
  min-width: 210px;
}
@media screen and (max-width: 767px) {
  .block-card .card-time {
    min-width: 150px;
  }
}
.block-card .card-time__value {
  font-family: "Advent Pro", sans-serif;
  font-weight: 600;
  font-size: 200px;
  line-height: 89%;
  color: #dcebc1;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .block-card .card-time__value {
    font-size: 140px;
  }
}
.block-card .card-time__text {
  margin-top: 6px;
  font-weight: 700;
  font-size: 21px;
  line-height: 83%;
  color: #dcebc1;
  text-transform: uppercase;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .block-card .card-time__text {
    margin-top: 4px;
    font-size: 16px;
  }
}

.table-card {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
@media screen and (max-width: 767px) {
  .table-card {
    flex-direction: row;
    align-items: center;
    gap: 15px;
    height: auto;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 449px) {
  .table-card {
    flex-direction: column;
  }
}
.table-card .card-list {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  min-height: 350px;
  gap: 25px;
  padding: 45px 28px 28px;
  border: 1px solid rgba(255, 255, 255, 0.24);
  border-radius: 8px;
}
@media screen and (max-width: 767px) {
  .table-card .card-list {
    width: 250px;
    min-height: 0;
    padding: 20px;
    gap: 20px;
  }
}
@media screen and (max-width: 449px) {
  .table-card .card-list {
    width: 100%;
  }
}
.table-card .card-list__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}
.table-card .card-list__item img {
  width: auto;
  max-height: 70px;
}
.table-card .card-list__item p {
  font-size: 15px;
  line-height: 140%;
  color: #d9d9d9;
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .table-card .card-list__item p {
    font-size: 14px;
  }
}
.table-card__info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.table-card__title {
  font-family: "Technor", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #fefefe;
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .table-card__title {
    font-size: 16px;
  }
}
.table-card__description {
  font-size: 16px;
  line-height: 140%;
  color: #d9d9d9;
}
@media screen and (max-width: 767px) {
  .table-card__description {
    font-size: 14px;
  }
}
.table-card--long .card-list {
  gap: 15px;
  padding-top: 22px;
}
.table-card--long .card-list__item img {
  max-height: 60px;
}
.table-card--long .card-list__item:first-child img {
  max-height: 65px;
}

.text-card {
  width: 100%;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.24);
  border-radius: 8px;
}
@media screen and (max-width: 767px) {
  .text-card {
    border-radius: 6px;
  }
}
.text-card__title {
  font-family: "Technor", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #fefefe;
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .text-card__title {
    font-size: 16px;
  }
}
.text-card__title span {
  color: #0cc166;
}
.text-card__description {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 15px;
  font-size: 16px;
  line-height: 140%;
  color: #d9d9d9;
}
@media screen and (max-width: 767px) {
  .text-card__description {
    gap: 10px;
    margin-top: 10px;
    font-size: 14px;
  }
}

.page-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 17px;
  padding-top: 155px;
  padding-bottom: 285px;
}
@media screen and (max-width: 1023px) {
  .page-banner {
    padding-top: 150px;
    padding-bottom: 200px;
  }
}
@media screen and (max-width: 767px) {
  .page-banner {
    gap: 10px;
    padding-top: 120px;
    padding-bottom: 120px;
  }
}
.page-banner__back {
  display: flex;
  align-items: center;
  gap: 12px;
  transition: opacity 0.3s;
}
.page-banner__back:hover {
  opacity: 0.6;
}
.page-banner__back img {
  width: 6px;
}
.page-banner__back p {
  font-size: 26px;
  line-height: 140%;
  color: #d9d9d9;
}
@media screen and (max-width: 767px) {
  .page-banner__back p {
    font-size: 16px;
  }
}
.page-banner__title {
  font-family: "Technor", sans-serif;
  font-weight: 500;
  font-size: 68px;
  line-height: 100%;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .page-banner__title {
    font-size: 35px;
  }
}
.page-banner__title span {
  color: #dcebc1;
}

.cta-banner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding-top: 340px;
  padding-bottom: 150px;
}
@media screen and (max-width: 767px) {
  .cta-banner {
    padding-top: 150px;
    padding-bottom: 50px;
  }
}
.cta-banner__title {
  font-family: "Technor", sans-serif;
  font-weight: 500;
  font-size: 48px;
  line-height: 100%;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .cta-banner__title {
    font-size: 30px;
  }
}
.cta-banner__title span {
  color: #dcebc1;
}
.cta-banner__link {
  font-family: "Technor", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: 0.16px;
  color: #fefefe;
  text-transform: uppercase;
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 5px;
  transition: opacity 0.3s;
}
@media screen and (max-width: 767px) {
  .cta-banner__link {
    font-size: 16px;
  }
}
.cta-banner__link:hover {
  opacity: 0.6;
}
.cta-banner__object {
  position: absolute;
  pointer-events: none;
  z-index: -1;
}
.cta-banner__object--logo {
  top: 90px;
  right: calc(50% + 184px);
  width: 331px;
}
@media screen and (max-width: 767px) {
  .cta-banner__object--logo {
    top: 50px;
    right: 70%;
    width: 280px;
  }
}
.cta-banner__object--airplane {
  top: 210px;
  left: calc(50% + 50px);
  width: 858px;
}
@media screen and (max-width: 767px) {
  .cta-banner__object--airplane {
    top: 130px;
    left: 70%;
    transform: translateX(-50%);
    width: 500px;
    max-width: initial;
  }
}

.icon-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}
.icon-block__img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 70px;
  height: 65px;
}
@media screen and (max-width: 767px) {
  .icon-block__img {
    width: 60px;
    height: 50px;
  }
}
.icon-block__title {
  font-family: "Advent Pro", sans-serif;
  font-weight: 400;
  font-size: 54px;
  line-height: 91%;
  color: #d9d9d9;
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .icon-block__title {
    font-size: 40px;
    line-height: 100%;
  }
}
.icon-block__subtitle {
  font-family: "Technor", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #fefefe;
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .icon-block__subtitle {
    font-size: 14px;
    line-height: 110%;
  }
}

.text-columns {
  justify-content: center;
  padding-bottom: 40px;
}
@media screen and (max-width: 767px) {
  .text-columns {
    padding-bottom: 0;
  }
}
.text-columns__column {
  display: flex;
  flex-direction: column;
  gap: 42px;
  margin-top: 54px;
}
@media screen and (max-width: 767px) {
  .text-columns__column {
    gap: 20px;
    margin-top: 20px;
  }
}
.text-columns__column:nth-child(2) {
  transform: translate(17px, 11px);
}
@media screen and (max-width: 1439px) {
  .text-columns__column:nth-child(2) {
    transform: none;
  }
}
.text-columns__column p {
  font-size: 16px;
  line-height: 140%;
  color: #d9d9d9;
}
@media screen and (max-width: 767px) {
  .text-columns__column p {
    font-size: 14px;
  }
}
.text-columns__column p span {
  color: #0cc166;
}

.map {
  position: relative;
  width: 682px;
  pointer-events: none;
  z-index: -1;
}
@media screen and (max-width: 767px) {
  .map {
    width: 500px;
  }
}
@media screen and (max-width: 449px) {
  .map {
    width: 100vw;
  }
}
.map__image {
  width: 100%;
  max-width: initial;
}
.map__locations .location {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 2px;
  animation-name: blink;
  animation-duration: 4s;
  transform-origin: 50% 50%;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
@keyframes blink {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  20% {
    opacity: 1;
    transform: scale(1.05);
  }
  25% {
    transform: scale(1);
  }
  75% {
    transform: scale(1);
  }
  80% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.map__locations .location img {
  width: 8px;
}
@media screen and (max-width: 767px) {
  .map__locations .location img {
    width: 7px;
  }
}
@media screen and (max-width: 449px) {
  .map__locations .location img {
    width: 2vw;
    transform: translateY(-50%);
  }
}
.map__locations .location p {
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}
@media screen and (max-width: 767px) {
  .map__locations .location p {
    font-size: 10px;
    line-height: 100%;
  }
}
@media screen and (max-width: 449px) {
  .map__locations .location p {
    transform: translateY(-50%);
    font-size: 2.5vw;
  }
}
.map__locations .location:nth-child(1) {
  top: 40%;
  left: 12.317%;
  animation-delay: 0.5s;
}
.map__locations .location:nth-child(2) {
  top: 46.7%;
  left: 23.9%;
}
.map__locations .location:nth-child(3) {
  top: 29.8%;
  left: 46.481%;
  animation-delay: 1s;
}
.map__locations .location:nth-child(4) {
  top: 27.705%;
  left: 50%;
}
.map__locations .location:nth-child(5) {
  top: 48.8%;
  left: 61.144%;
  animation-delay: 0.5s;
}
.map__locations .location:nth-child(6) {
  top: 46.7%;
  left: 61.8%;
}
.map__locations .location:nth-child(7) {
  top: 62.533%;
  left: 74.194%;
  animation-delay: 1s;
}
.map__locations .location:nth-child(8) {
  top: 65.172%;
  left: 75.513%;
}
.map__locations .location:nth-child(9) {
  top: 47.757%;
  left: 78.886%;
  animation-delay: 0.5s;
}

/**********************************************************************/
/**********************************************************************/
body {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  margin: 0 auto;
  background: linear-gradient(298.14deg, #101a29 18.19%, #000000 90.82%);
  font: normal 400 16px/140% "Share", sans-serif;
  color: #d9d9d9;
  cursor: default;
  /******************************************************************/
  /******************************************************************/
  /******************************************************************/
}
body.no-scroll {
  overflow: hidden;
}
body * {
  box-sizing: border-box;
  margin: 0;
}
body img,
body svg {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
body a {
  display: inline-block;
  text-decoration: none;
}
body .disabled,
body .disabled * {
  pointer-events: none;
  cursor: not-allowed;
}
body .hide {
  display: none !important;
}
@media screen and (min-width: 768px) {
  body .hide-desk {
    display: none !important;
  }
}
@media screen and (max-width: 767px) {
  body .hide-mob {
    display: none !important;
  }
}
body .scrollbar {
  overflow: auto;
}
body .scrollbar::-webkit-scrollbar {
  height: 0;
  width: 2px;
}
body .scrollbar::-webkit-scrollbar-track {
  background: transparent;
}
body .scrollbar::-webkit-scrollbar-button {
  display: none;
}
body .scrollbar::-webkit-scrollbar-thumb {
  background-color: #0cc166;
}

/**********************************************************************/
/**********************************************************************/
.container {
  display: flex;
  flex-direction: column;
}
.container__content {
  width: 1240px;
  max-width: calc(100% - 200px);
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 1023px) {
  .container__content {
    max-width: calc(100% - 60px);
  }
}
@media screen and (max-width: 767px) {
  .container__content {
    max-width: calc(100% - 40px);
  }
}

/**********************************************************************/
/**********************************************************************/
.row {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 24px);
  margin-left: -12px;
  margin-right: -12px;
}

/**********************************************************************/
/**********************************************************************/
.col {
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  padding-right: 12px;
}
.col-0 {
  padding: 0;
}
.col-0 {
  width: 0%;
}
.col-1 {
  width: 8.3333333333%;
}
.col-2 {
  width: 16.6666666667%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.3333333333%;
}
.col-5 {
  width: 41.6666666667%;
}
.col-6 {
  width: 50%;
}
.col-7 {
  width: 58.3333333333%;
}
.col-8 {
  width: 66.6666666667%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.3333333333%;
}
.col-11 {
  width: 91.6666666667%;
}
.col-12 {
  width: 100%;
}
@media screen and (max-width: 1439px) {
  .col-xl-0 {
    padding: 0;
  }
  .col-xl-0 {
    width: 0%;
  }
  .col-xl-1 {
    width: 8.3333333333%;
  }
  .col-xl-2 {
    width: 16.6666666667%;
  }
  .col-xl-3 {
    width: 25%;
  }
  .col-xl-4 {
    width: 33.3333333333%;
  }
  .col-xl-5 {
    width: 41.6666666667%;
  }
  .col-xl-6 {
    width: 50%;
  }
  .col-xl-7 {
    width: 58.3333333333%;
  }
  .col-xl-8 {
    width: 66.6666666667%;
  }
  .col-xl-9 {
    width: 75%;
  }
  .col-xl-10 {
    width: 83.3333333333%;
  }
  .col-xl-11 {
    width: 91.6666666667%;
  }
  .col-xl-12 {
    width: 100%;
  }
}
@media screen and (max-width: 1239px) {
  .col-lg-0 {
    padding: 0;
  }
  .col-lg-0 {
    width: 0%;
  }
  .col-lg-1 {
    width: 8.3333333333%;
  }
  .col-lg-2 {
    width: 16.6666666667%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-4 {
    width: 33.3333333333%;
  }
  .col-lg-5 {
    width: 41.6666666667%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-7 {
    width: 58.3333333333%;
  }
  .col-lg-8 {
    width: 66.6666666667%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-10 {
    width: 83.3333333333%;
  }
  .col-lg-11 {
    width: 91.6666666667%;
  }
  .col-lg-12 {
    width: 100%;
  }
}
@media screen and (max-width: 1023px) {
  .col-md-0 {
    padding: 0;
  }
  .col-md-0 {
    width: 0%;
  }
  .col-md-1 {
    width: 8.3333333333%;
  }
  .col-md-2 {
    width: 16.6666666667%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33.3333333333%;
  }
  .col-md-5 {
    width: 41.6666666667%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58.3333333333%;
  }
  .col-md-8 {
    width: 66.6666666667%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83.3333333333%;
  }
  .col-md-11 {
    width: 91.6666666667%;
  }
  .col-md-12 {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .col-sm-0 {
    padding: 0;
  }
  .col-sm-0 {
    width: 0%;
  }
  .col-sm-1 {
    width: 8.3333333333%;
  }
  .col-sm-2 {
    width: 16.6666666667%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-4 {
    width: 33.3333333333%;
  }
  .col-sm-5 {
    width: 41.6666666667%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-7 {
    width: 58.3333333333%;
  }
  .col-sm-8 {
    width: 66.6666666667%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-10 {
    width: 83.3333333333%;
  }
  .col-sm-11 {
    width: 91.6666666667%;
  }
  .col-sm-12 {
    width: 100%;
  }
}
@media screen and (max-width: 449px) {
  .col-xs-0 {
    padding: 0;
  }
  .col-xs-0 {
    width: 0%;
  }
  .col-xs-1 {
    width: 8.3333333333%;
  }
  .col-xs-2 {
    width: 16.6666666667%;
  }
  .col-xs-3 {
    width: 25%;
  }
  .col-xs-4 {
    width: 33.3333333333%;
  }
  .col-xs-5 {
    width: 41.6666666667%;
  }
  .col-xs-6 {
    width: 50%;
  }
  .col-xs-7 {
    width: 58.3333333333%;
  }
  .col-xs-8 {
    width: 66.6666666667%;
  }
  .col-xs-9 {
    width: 75%;
  }
  .col-xs-10 {
    width: 83.3333333333%;
  }
  .col-xs-11 {
    width: 91.6666666667%;
  }
  .col-xs-12 {
    width: 100%;
  }
}

/**********************************************************************/
/**********************************************************************/
.main {
  position: relative;
  padding-top: 80px;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .main {
    padding-top: 60px;
  }
}
.main::before {
  content: "";
  position: absolute;
  left: 69px;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.25);
}
@media screen and (max-width: 1023px) {
  .main::before {
    display: none;
  }
}

/**********************************************************************/
/**********************************************************************/
.header {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
  width: 100vw;
  max-width: inherit;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  background-color: #000000;
  z-index: 60;
}
@media screen and (max-width: 767px) {
  .header {
    height: 60px;
  }
}
.header__side {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  border-right: 1px solid rgba(255, 255, 255, 0.25);
}
@media screen and (max-width: 1023px) {
  .header__side {
    display: none;
  }
}
.header__side img {
  width: 25px;
}
.header__content {
  display: flex;
  align-items: center;
}
.header__logo {
  height: 20px;
  margin-right: auto;
  transition: opacity 0.3s;
}
@media screen and (max-width: 767px) {
  .header__logo {
    height: 17px;
  }
}
@media screen and (max-width: 449px) {
  .header__logo {
    height: 30px;
  }
}
.header__logo:hover {
  opacity: 0.6;
}
.header__menu {
  margin-left: 60px;
  margin-right: 100px;
}
@media screen and (max-width: 1239px) {
  .header__menu {
    margin-left: 30px;
    margin-right: 60px;
  }
}
@media screen and (max-width: 1023px) {
  .header__menu {
    position: absolute;
    left: 0;
    top: 80px;
    transform: translateX(-100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: calc(100vh - 80px);
    margin: 0;
    padding: 40px;
    background: linear-gradient(298.14deg, #101a29 18.19%, #000000 90.82%);
    pointer-events: none;
    overflow: hidden;
    transition: transform 0.3s;
    z-index: 80;
  }
}
@media screen and (max-width: 767px) {
  .header__menu {
    top: 60px;
    height: calc(100vh - 60px);
  }
}
.header__menu .menu__list {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 5px 30px;
  padding: 0;
  list-style: none;
}
@media screen and (max-width: 1023px) {
  .header__menu .menu__list {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}
.header__menu .menu__list .menu-item a {
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 1.17px;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  transition: opacity 0.3s;
}
.header__menu .menu__list .menu-item a:hover {
  opacity: 0.6;
}
.header__button {
  position: relative;
  display: flex;
  flex-direction: column;
}
.header__button::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 6px;
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.21) 50%, rgb(255, 255, 255) 100%);
}
.header__button span {
  position: relative;
  margin: 1px;
  padding: 13px 33px;
  border-radius: 6px;
  background-color: #000000;
  font-family: "Technor", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.14px;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  transition: background-color 0.3s, color 0.3s;
}
@media screen and (max-width: 767px) {
  .header__button span {
    padding: 9px 18px;
    font-size: 12px;
  }
}
.header__button:hover span {
  background-color: #ffffff;
  color: #000000;
}
.header__burger {
  position: relative;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin: auto 20px auto 0;
  cursor: pointer;
}
@media screen and (max-width: 1023px) {
  .header__burger {
    display: flex;
  }
}
@media screen and (max-width: 767px) {
  .header__burger {
    width: 24px;
    height: 24px;
    margin-right: 15px;
  }
}
.header__burger span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.75);
  transform-origin: center;
  transition: transform 0.3s, opacity 0.3s;
}
.header__burger span:nth-child(1) {
  transform: translate(-50%, calc(-50% - 8px));
}
@media screen and (max-width: 767px) {
  .header__burger span:nth-child(1) {
    transform: translate(-50%, calc(-50% - 6px));
  }
}
.header__burger span:nth-child(3) {
  transform: translate(-50%, calc(-50% + 8px));
}
@media screen and (max-width: 767px) {
  .header__burger span:nth-child(3) {
    transform: translate(-50%, calc(-50% + 6px));
  }
}
.header.show-menu .header__burger span:nth-child(1) {
  transform: translate(-50%, -50%) rotate(45deg);
}
.header.show-menu .header__burger span:nth-child(2) {
  opacity: 0;
}
.header.show-menu .header__burger span:nth-child(3) {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.header.show-menu .header__menu {
  transform: none;
  pointer-events: initial;
}

.footer {
  padding-top: 54px;
  padding-bottom: 54px;
  background-color: rgba(255, 255, 255, 0.21);
}
@media screen and (max-width: 767px) {
  .footer {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.footer .col:nth-child(2) .footer__info {
  margin-left: 30px;
}
@media screen and (max-width: 1239px) {
  .footer .col:nth-child(2) .footer__info {
    margin-left: 0;
  }
}
.footer__info {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  line-height: 17px;
  letter-spacing: 0.16px;
  color: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 767px) {
  .footer__info {
    align-items: center;
    margin-bottom: 20px;
    text-align: center;
  }
}
.footer__info a {
  color: inherit;
  transition: color 0.3s;
}
.footer__info a:hover {
  color: rgb(255, 255, 255);
}
.footer__logo {
  display: flex;
  justify-content: flex-end;
  height: 45px;
}
@media screen and (max-width: 767px) {
  .footer__logo {
    justify-content: center;
    margin-top: 20px;
  }
}

.formarea input,
.formarea textarea {
  outline: none;
  appearance: none;
}
.formarea__line {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
@media screen and (max-width: 767px) {
  .formarea__line {
    margin-bottom: 16px;
  }
}
.formarea__line input,
.formarea__line textarea {
  display: block;
  width: 100%;
  padding: 19px 19px 17px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  background-color: transparent;
  font-family: "Share", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: #ffffff;
  outline: none;
  transition: border-color 0.3s, color 0.3s;
}
.formarea__line input::placeholder,
.formarea__line textarea::placeholder {
  color: #808080;
}
.formarea__line input:focus ~ .formarea__label, .formarea__line input:not(.is-empty) ~ .formarea__label,
.formarea__line textarea:focus ~ .formarea__label,
.formarea__line textarea:not(.is-empty) ~ .formarea__label {
  opacity: 0;
}
.formarea__line input.error,
.formarea__line textarea.error {
  border-color: #aa2121;
  color: #aa2121;
}
.formarea__line input.error::placeholder, .formarea__line input.error ~ .formarea__label, .formarea__line input.error ~ .formarea__label span,
.formarea__line textarea.error::placeholder,
.formarea__line textarea.error ~ .formarea__label,
.formarea__line textarea.error ~ .formarea__label span {
  color: #aa2121;
}
@media screen and (max-width: 767px) {
  .formarea__line input,
  .formarea__line textarea {
    padding: 12px 16px;
    border-radius: 4px;
    font-size: 16px;
  }
}
.formarea__line textarea {
  height: 172px;
  resize: none;
}
@media screen and (max-width: 767px) {
  .formarea__line textarea {
    height: 120px;
  }
}
.formarea__label {
  position: absolute;
  left: 20px;
  top: 20px;
  font-size: 18px;
  line-height: 120%;
  color: #808080;
  transition: opacity 0.3s, color 0.3s;
}
@media screen and (max-width: 767px) {
  .formarea__label {
    left: 17px;
    top: 13px;
    font-size: 16px;
  }
}
.formarea__label span {
  color: #0cc166;
}
.formarea__submit {
  display: flex;
  justify-content: flex-start;
}
.formarea__submit input {
  width: 220px;
  max-width: 100%;
  padding: 20px;
  border: none;
  border-radius: 6px;
  background-color: #ffffff;
  font-family: "Technor", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.14px;
  color: #0a111a;
  text-transform: uppercase;
  text-align: center;
  transition: opacity 0.3s;
  cursor: pointer;
}
.formarea__submit input:hover {
  opacity: 0.6;
}
@media screen and (max-width: 767px) {
  .formarea__submit {
    justify-content: center;
  }
  .formarea__submit input {
    width: 160px;
    padding: 10px;
    border-radius: 4px;
    font-size: 14px;
  }
}
.formarea label.error {
  display: none !important;
}
.formarea__response {
  font-size: 16px;
  line-height: 20px;
  color: #7ebb00;
}
.formarea__response.error {
  color: #aa2121;
}
@media screen and (max-width: 767px) {
  .formarea__response {
    font-size: 14px;
    text-align: center;
  }
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  opacity: 0;
  z-index: 200;
  transition: opacity 0.3s;
}
.popup.show {
  opacity: 1;
  pointer-events: initial;
}
.popup__overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
}
.popup__body {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 60px);
  padding: 100px 0 72px;
  background: linear-gradient(26.18deg, #101a29 29.72%, #000000 98.1%, #000000 98.11%);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.8);
  z-index: 10;
}
@media screen and (max-width: 767px) {
  .popup__body {
    max-height: calc(100% - 40px);
    padding: 40px 20px 20px;
  }
}
.popup__close {
  position: absolute;
  top: 36px;
  right: 36px;
  width: 24px;
  height: 24px;
  transition: transform 0.3s;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .popup__close {
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
  }
}
.popup__close:hover {
  transform: rotate(-90deg);
}
.popup__close::before, .popup__close::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 19px;
  height: 2px;
  border-radius: 1px;
  background-color: #fefefe;
}
@media screen and (max-width: 767px) {
  .popup__close::before, .popup__close::after {
    width: 16px;
  }
}
.popup__close::before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.popup__close::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.popup__content {
  overflow-x: hidden;
  overflow-y: auto;
}
@media screen and (max-width: 767px) {
  .popup__content {
    margin-right: -10px;
    padding-right: 10px;
  }
}
.popup__title {
  margin-bottom: 17px;
  font-family: "Technor", sans-serif;
  font-weight: 500;
  font-size: 48px;
  line-height: 100%;
  letter-spacing: -0.66px;
  color: #fefefe;
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .popup__title {
    margin-bottom: 10px;
    font-size: 32px;
  }
}
.popup__text {
  font-size: 16px;
  line-height: 140%;
  color: #d9d9d9;
}
@media screen and (max-width: 767px) {
  .popup__text {
    margin-bottom: 20px;
    font-size: 14px;
  }
}
.popup__text span {
  color: #0cc166;
}
.popup__bg {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 298px;
  pointer-events: none;
  z-index: -1;
}
@media screen and (max-width: 767px) {
  .popup__bg {
    height: 160px;
  }
}

.page-home {
  position: relative;
}
.page-home__object {
  position: absolute;
  pointer-events: none;
  z-index: -1;
}
.page-home__object--gear {
  top: 120px;
  right: -128px;
  width: 350px;
  animation: rotate 9s linear;
  animation-iteration-count: infinite;
}
.page-home__object--turbine {
  top: 500px;
  right: -60px;
  width: 449px;
  transform: rotate(-4.22deg);
}
.page-home__object--logo {
  top: 758px;
  left: -77px;
  width: 365px;
}
.page-home__object--grid {
  top: 1643px;
  right: 0;
  width: 1114px;
}
.page-home__object--helecopter {
  top: 1441px;
  right: -136px;
  width: 868px;
  mix-blend-mode: screen;
  opacity: 0.6;
}
.page-home__object--airplane {
  top: 2288px;
  right: -190px;
  width: 835px;
  transform: rotate(-60deg);
}
.page-home .home-banner {
  margin-top: 8px;
  margin-bottom: 60px;
  padding-top: 140px;
  padding-bottom: 114px;
}
@media screen and (max-width: 767px) {
  .page-home .home-banner {
    margin-top: 0;
    margin-bottom: 20px;
    padding-top: 60px;
    padding-bottom: 40px;
  }
}
.page-home .home-banner__title {
  margin-bottom: 30px;
  font-family: "Technor", sans-serif;
  font-weight: 500;
  font-size: 68px;
  line-height: 100%;
  color: #ffffff;
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .page-home .home-banner__title {
    margin-bottom: 20px;
    font-size: 40px;
  }
}
.page-home .home-banner__title span {
  color: rgb(220, 235, 193);
}
.page-home .home-banner__subtitle {
  width: 500px;
  max-width: 100%;
  font-size: 16px;
  line-height: 140%;
  color: #d9d9d9;
}
@media screen and (max-width: 767px) {
  .page-home .home-banner__subtitle {
    font-size: 14px;
  }
}
.page-home .marquee {
  height: 200px;
  margin-top: 70px;
  margin-bottom: 110px;
}
@media screen and (max-width: 767px) {
  .page-home .marquee {
    height: 80px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.page-home .marquee__txt {
  margin-right: 120px;
  font-family: "Technor", sans-serif;
  font-weight: 900;
  font-size: 200px;
  line-height: 100%;
  letter-spacing: 6px;
  color: #1e2228;
  text-transform: uppercase;
  -webkit-text-stroke: 1px #000000;
  -webkit-text-fill-color: #1e2228;
}
@media screen and (max-width: 767px) {
  .page-home .marquee__txt {
    margin-right: 30px;
    font-size: 80px;
    letter-spacing: 2px;
  }
}
.page-home__lists {
  display: flex;
  flex-direction: column;
  gap: 200px;
  margin-top: 192px;
  margin-bottom: 200px;
}
@media screen and (max-width: 767px) {
  .page-home__lists {
    gap: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
.page-home__lists .home-list {
  position: relative;
}
.page-home__lists .home-list__title {
  position: absolute;
  top: 5px;
  left: -20px;
  transform: translate(-100%, -100%) rotate(-90deg);
  transform-origin: right bottom;
  font-family: "Technor", sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 100%;
  letter-spacing: 2px;
  color: #dcebc1;
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .page-home__lists .home-list__title {
    top: 5px;
    left: 32px;
    font-size: 20px;
    letter-spacing: 1px;
  }
}
.page-home__lists .home-list__values {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding-left: 20px;
  list-style: square;
  font-size: 16px;
  line-height: 140%;
  color: #d9d9d9;
}
@media screen and (max-width: 767px) {
  .page-home__lists .home-list__values {
    gap: 4px;
    padding-left: 50px;
    font-size: 14px;
  }
}

.page-about {
  position: relative;
}
.page-about__object {
  position: absolute;
  pointer-events: none;
  z-index: -1;
}
.page-about__object--airplane {
  top: 110px;
  left: calc(50% + 53px);
  width: 1114px;
}
@media screen and (max-width: 1023px) {
  .page-about__object--airplane {
    top: 60px;
    left: 85%;
    transform: translateX(-50%);
    width: 800px;
    max-width: initial;
  }
}
.page-about__object--grid {
  top: 270px;
  left: 51px;
  width: 496px;
}
.page-about__map {
  position: absolute;
  top: 938px;
  left: calc(50% - 10px);
}
@media screen and (max-width: 1239px) {
  .page-about__map {
    left: 40%;
  }
}
@media screen and (max-width: 1023px) {
  .page-about__map {
    left: initial;
    right: -5%;
  }
}
@media screen and (max-width: 767px) {
  .page-about__map {
    top: 600px;
    right: -8%;
  }
}
@media screen and (max-width: 550px) {
  .page-about__map {
    top: 700px;
  }
}
@media screen and (max-width: 449px) {
  .page-about__map {
    position: relative;
    top: initial;
    right: initial;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 50px;
  }
}
.page-about .block-card {
  width: auto;
  margin-bottom: 156px;
  padding: 0;
  border: none;
  border-radius: 0;
}
@media screen and (max-width: 767px) {
  .page-about .block-card {
    margin-bottom: 100px;
  }
}
.page-about .block-card__body {
  justify-content: flex-start;
  gap: 0;
}
@media screen and (max-width: 449px) {
  .page-about .block-card__body {
    align-items: center;
    gap: 20px;
  }
}
.page-about .block-card__text {
  width: 100%;
  margin-top: 0;
}
.page-about .block-card .card-time {
  margin-top: 40px;
}
@media screen and (max-width: 1023px) {
  .page-about .block-card .card-time {
    align-self: flex-end;
    margin-top: 0;
  }
}
.page-about__icon-list {
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin-top: 75px;
  margin-bottom: 186px;
}
@media screen and (max-width: 767px) {
  .page-about__icon-list {
    gap: 30px;
    margin-top: 30px;
    margin-bottom: 80px;
  }
}
.page-about__icon-list .icon-block:last-child {
  margin-left: 20px;
}
@media screen and (max-width: 1023px) {
  .page-about__icon-list .icon-block:last-child {
    margin-left: 0;
  }
}
@media screen and (max-width: 449px) {
  .page-about__icon-list .icon-block:last-child {
    margin-top: 30px;
  }
}
.page-about .cta-banner {
  margin-top: 92px;
}
@media screen and (max-width: 550px) {
  .page-about .cta-banner {
    margin-top: 150px;
  }
}
@media screen and (max-width: 449px) {
  .page-about .cta-banner {
    margin-top: -50px;
  }
}

.page-engines {
  position: relative;
}
.page-engines__object {
  position: absolute;
  pointer-events: none;
  z-index: -1;
}
.page-engines__object--turbine {
  top: 74px;
  right: -254px;
  width: 888px;
  max-width: initial;
}
.page-engines__object--engine {
  top: 502px;
  right: -98px;
  width: 338px;
  transform: rotate(180deg);
}
.page-engines__object--engine-grid {
  top: 1523px;
  right: -395px;
  width: 854px;
}
.page-engines__object--grid {
  top: 270px;
  left: 51px;
  width: 496px;
}
.page-engines__object--gear-grid {
  top: 2383px;
  left: 10px;
  width: 621px;
}
.page-engines__object--gear {
  top: 2488px;
  left: 10px;
  width: 350px;
  animation: rotate 9s linear;
  animation-iteration-count: infinite;
}
@media screen and (max-width: 1023px) {
  .page-engines__object--gear {
    top: 2200px;
    width: 300px;
  }
}
@media screen and (max-width: 767px) {
  .page-engines__object--gear {
    top: 1900px;
  }
}
.page-engines .text-large {
  margin-top: 30px;
  margin-bottom: 145px;
  font-family: "Technor", sans-serif;
  font-weight: 500;
  font-size: 48px;
  line-height: 100%;
  letter-spacing: -0.66px;
  color: #fefefe;
  text-transform: uppercase;
}
@media screen and (max-width: 1023px) {
  .page-engines .text-large {
    font-size: 36px;
  }
}
@media screen and (max-width: 767px) {
  .page-engines .text-large {
    margin-top: 15px;
    margin-bottom: 100px;
    font-size: 24px;
    line-height: 110%;
  }
}
.page-engines .text-large span {
  color: #dcebc1;
}
.page-engines__table-cards {
  margin-top: 60px;
  margin-bottom: 245px;
}
@media screen and (max-width: 1023px) {
  .page-engines__table-cards {
    margin-top: 40px;
    margin-bottom: 180px;
  }
}
@media screen and (max-width: 767px) {
  .page-engines__table-cards {
    margin-top: 20px;
    margin-bottom: 70px;
  }
}
.page-engines__note {
  width: 860px;
  max-width: 100%;
  margin: 40px auto 0;
  border: 1px solid #0cc166;
  border-radius: 8px;
}
@media screen and (max-width: 767px) {
  .page-engines__note {
    margin-top: 0;
  }
}
.page-engines__note p {
  padding: 16px 32px;
  font-family: "Technor", sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 100%;
  letter-spacing: -0.66px;
  color: #fefefe;
  text-align: center;
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .page-engines__note p {
    font-size: 20px;
    line-height: 120%;
  }
}
.page-engines .cta-banner {
  margin-top: 85px;
}
@media screen and (max-width: 767px) {
  .page-engines .cta-banner {
    margin-top: 0;
  }
}

.page-inspections {
  position: relative;
}
.page-inspections__object {
  position: absolute;
  pointer-events: none;
  z-index: -1;
  max-width: 200vw;
}
.page-inspections__object--grid {
  top: 270px;
  left: 51px;
  width: 496px;
}
.page-inspections__object--building {
  top: 177px;
  right: -238px;
  width: 945px;
}
.page-inspections__object--airplane {
  top: 1041px;
  left: -52px;
  width: 900px;
}
@media screen and (max-width: 767px) {
  .page-inspections__object--airplane {
    left: 30%;
    transform: translateX(-50%);
    width: 700px;
  }
}
.page-inspections__object--engine {
  top: 2654px;
  right: -55px;
  width: 352px;
  animation: rotate 6s linear;
  animation-iteration-count: infinite;
}
@media screen and (max-width: 767px) {
  .page-inspections__object--engine {
    top: 1900px;
    width: 240px;
  }
}
.page-inspections__object--engine-grid {
  top: 2618px;
  right: -140px;
  width: 700px;
}
.page-inspections .text-columns {
  margin-bottom: 100px;
}
@media screen and (max-width: 767px) {
  .page-inspections .text-columns {
    margin-bottom: 80px;
  }
}
@media screen and (min-width: 1240px) {
  .page-inspections .text-columns__column:nth-child(1) {
    padding-right: 32px;
  }
}
.page-inspections .text-columns__column:nth-child(2) {
  padding-top: 42px;
  transform: none;
}
@media screen and (max-width: 1023px) {
  .page-inspections .text-columns__column:nth-child(2) {
    padding-top: 0;
  }
}
.page-inspections .titlearea__text {
  width: 400px;
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .page-inspections .titlearea__text {
    width: 100%;
  }
}
.page-inspections .person-block {
  width: 220px;
  max-width: 100%;
  margin-top: 44px;
  margin-left: -24px;
}
@media screen and (max-width: 1239px) {
  .page-inspections .person-block {
    margin-left: auto;
  }
}
@media screen and (max-width: 767px) {
  .page-inspections .person-block {
    width: 200px;
    margin-top: 30px;
    margin-right: auto;
  }
}
.page-inspections .person-block > img {
  margin-bottom: 25px;
}
@media screen and (max-width: 767px) {
  .page-inspections .person-block > img {
    margin-bottom: 15px;
  }
}
.page-inspections .person-block__title {
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: #d9d9d9;
}
@media screen and (max-width: 767px) {
  .page-inspections .person-block__title {
    font-size: 14px;
  }
}
.page-inspections .person-block__subtitle {
  font-size: 16px;
  line-height: 140%;
  color: #d9d9d9;
}
@media screen and (max-width: 767px) {
  .page-inspections .person-block__subtitle {
    font-size: 14px;
  }
}
.page-inspections .person-block__icons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}
.page-inspections .person-block__icons img {
  height: 20px;
}
.page-inspections__icon-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 55px;
  margin-top: 220px;
  margin-bottom: 150px;
}
@media screen and (max-width: 1023px) {
  .page-inspections__icon-list {
    margin-top: 150px;
  }
}
@media screen and (max-width: 767px) {
  .page-inspections__icon-list {
    gap: 30px;
    margin-top: 100px;
    margin-bottom: 100px;
  }
}
.page-inspections__icon-list > p {
  width: 850px;
  max-width: 100%;
  font-family: "Technor", sans-serif;
  font-weight: 500;
  font-size: 48px;
  line-height: 100%;
  letter-spacing: -0.66px;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
}
@media screen and (max-width: 1023px) {
  .page-inspections__icon-list > p {
    font-size: 40px;
  }
}
@media screen and (max-width: 767px) {
  .page-inspections__icon-list > p {
    font-size: 27px;
  }
}
.page-inspections__icon-list > p span {
  color: #dcebc1;
}
.page-inspections__icon-list > .row {
  justify-content: center;
  row-gap: 30px;
}
.page-inspections__icon-list .icon-block {
  flex-direction: column;
}
.page-inspections__icon-list .icon-block__img {
  width: 65px;
  height: 50px;
}
@media screen and (max-width: 767px) {
  .page-inspections__icon-list .icon-block__img {
    width: 50px;
    height: 40px;
  }
}
.page-inspections__icon-list .icon-block__body {
  width: 290px;
  max-width: 100%;
}
.page-inspections__icon-list .icon-block__title {
  font-family: "Technor", sans-serif;
  font-size: 20px;
  line-height: 120%;
  color: #fefefe;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .page-inspections__icon-list .icon-block__title {
    font-size: 16px;
  }
}
.page-inspections__icon-list .icon-block__subtitle {
  margin-top: 6px;
  font-family: "Share", sans-serif;
  font-size: 16px;
  line-height: 140%;
  color: #d9d9d9;
  text-transform: initial;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .page-inspections__icon-list .icon-block__subtitle {
    font-size: 14px;
  }
}
.page-inspections__table-cards {
  margin-top: 60px;
  margin-bottom: 250px;
}
@media screen and (max-width: 1023px) {
  .page-inspections__table-cards {
    margin-top: 40px;
    margin-bottom: 180px;
  }
}
@media screen and (max-width: 767px) {
  .page-inspections__table-cards {
    margin-top: 20px;
    margin-bottom: 50px;
  }
}
.page-inspections__text-cards {
  margin-top: 50px;
}
@media screen and (max-width: 767px) {
  .page-inspections__text-cards {
    margin-top: 30px;
  }
}
.page-inspections__text-cards .text-card {
  height: 100%;
  margin-bottom: 28px;
}
@media screen and (max-width: 767px) {
  .page-inspections__text-cards .text-card {
    margin-bottom: 20px;
  }
}
.page-inspections .cta-banner {
  margin-top: -10px;
}
@media screen and (max-width: 767px) {
  .page-inspections .cta-banner {
    margin-top: 0;
  }
}

.page-contacts {
  position: relative;
}
.page-contacts .ruler-wrap {
  margin-top: 205px;
}
@media screen and (max-width: 767px) {
  .page-contacts .ruler-wrap {
    margin-top: 100px;
  }
}
@media screen and (max-width: 550px) {
  .page-contacts .ruler-wrap {
    margin-top: 60px;
  }
}
.page-contacts .titlearea {
  margin-bottom: 100px;
}
@media screen and (max-width: 550px) {
  .page-contacts .titlearea {
    margin-bottom: 30px;
  }
}
.page-contacts__list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 310px;
  max-width: 100%;
  margin-top: 55px;
}
@media screen and (max-width: 767px) {
  .page-contacts__list {
    gap: 15px;
    margin-top: 30px;
  }
}
.page-contacts__list .list-item {
  display: flex;
  align-items: flex-start;
  gap: 7px;
}
.page-contacts__list .list-item[href] {
  transition: opacity 0.3s;
}
.page-contacts__list .list-item[href]:hover {
  opacity: 0.6;
}
.page-contacts__list .list-item__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 16px;
  height: 18px;
}
.page-contacts__list .list-item p {
  font-size: 15px;
  line-height: 17px;
  letter-spacing: 0.16px;
  color: #d9d9d9;
}
@media screen and (max-width: 767px) {
  .page-contacts__list .list-item p {
    font-size: 14px;
  }
}
.page-contacts__map {
  position: absolute;
  top: -40px;
  left: calc(50% - 10px);
}
@media screen and (max-width: 1239px) {
  .page-contacts__map {
    left: 40%;
  }
}
@media screen and (max-width: 1023px) {
  .page-contacts__map {
    top: -80px;
    left: initial;
    right: -5%;
  }
}
@media screen and (max-width: 767px) {
  .page-contacts__map {
    right: -8%;
  }
}
@media screen and (max-width: 550px) {
  .page-contacts__map {
    position: relative;
    top: initial;
    right: initial;
    left: 50%;
    transform: translateX(-50%);
  }
}
.page-contacts__map .map__grid {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-17.5%);
  width: 114.37%;
  pointer-events: none;
  z-index: -1;
}