.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    opacity: 0;
    z-index: 200;
    transition: opacity 0.3s;

    &.show {
        opacity: 1;
        pointer-events: initial;
    }

    &__overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.7);
        cursor: pointer;
    }

    &__body {
        position: relative;
        display: flex;
        flex-direction: column;
        max-height: calc(100% - 60px);
        padding: 100px 0 72px;
        background: linear-gradient(
            26.18deg,
            #101a29 29.72%,
            #000000 98.1%,
            #000000 98.11%
        );
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.8);
        z-index: 10;

        @include is-screen-max($breakpoint-sm) {
            max-height: calc(100% - 40px);
            padding: 40px 20px 20px;
        }
    }

    &__close {
        position: absolute;
        top: 36px;
        right: 36px;
        width: 24px;
        height: 24px;
        transition: transform 0.3s;
        cursor: pointer;

        @include is-screen-max($breakpoint-sm) {
            top: 10px;
            right: 10px;
            width: 20px;
            height: 20px;
        }

        &:hover {
            transform: rotate(-90deg);
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 19px;
            height: 2px;
            border-radius: 1px;
            background-color: #fefefe;

            @include is-screen-max($breakpoint-sm) {
                width: 16px;
            }
        }

        &::before {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &::after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }

    &__content {
        overflow-x: hidden;
        overflow-y: auto;

        @include is-screen-max($breakpoint-sm) {
            margin-right: -10px;
            padding-right: 10px;
        }
    }

    &__title {
        margin-bottom: 17px;
        font-family: "Technor", sans-serif;
        font-weight: 500;
        font-size: 48px;
        line-height: 100%;
        letter-spacing: -0.66px;
        color: #fefefe;
        text-transform: uppercase;

        @include is-screen-max($breakpoint-sm) {
            margin-bottom: 10px;
            font-size: 32px;
        }
    }

    &__text {
        font-size: 16px;
        line-height: 140%;
        color: #d9d9d9;

        @include is-screen-max($breakpoint-sm) {
            margin-bottom: 20px;
            font-size: 14px;
        }

        span {
            color: #0cc166;
        }
    }

    &__bg {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 298px;
        pointer-events: none;
        z-index: -1;

        @include is-screen-max($breakpoint-sm) {
            height: 160px;
        }
    }
}
