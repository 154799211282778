.titlearea {
    &__line {
        position: relative;
        height: 100%;

        &::before {
            content: "";
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: calc(100% + 50vw - 620px - 80px);
            height: 1px;
            background-color: rgba(255, 255, 255, 0.25);

            @include is-screen-max($breakpoint-xl) {
                width: calc(100% + 100px - 80px);
            }

            @include is-screen-max($breakpoint-md) {
                display: none;
            }
        }
    }

    &__subtitle {
        font-family: "Technor", sans-serif;
        font-weight: 500;
        font-size: 26px;
        line-height: 130%;
        letter-spacing: 2px;
        color: #0cc166;
        text-transform: uppercase;

        @include is-screen-max($breakpoint-sm) {
            font-size: 18px;
            letter-spacing: 1px;
        }
    }

    &__title {
        margin-top: 16px;
        font-family: "Technor", sans-serif;
        font-weight: 500;
        font-size: 48px;
        line-height: 100%;
        letter-spacing: -0.66px;
        color: #fefefe;
        text-transform: uppercase;

        @include is-screen-max($breakpoint-sm) {
            margin-top: 10px;
            font-size: 24px;
        }
    }

    &__list {
        margin-top: 32px;
        padding-left: 20px;
        font-family: "Technor", sans-serif;
        font-weight: 500;
        font-size: 20px;
        line-height: 120%;
        letter-spacing: 0.16px;
        color: #fefefe;
        text-transform: uppercase;

        @include is-screen-max($breakpoint-sm) {
            margin-top: 20px;
            font-size: 14px;
        }
    }

    &__text {
        width: 320px;
        max-width: 100%;
        margin-top: 30px;
        font-size: 16px;
        line-height: 140%;
        color: #d9d9d9;

        @include is-screen-max($breakpoint-sm) {
            margin-top: 10px;
            font-size: 14px;
        }
    }
}
