.footer {
    padding-top: 54px;
    padding-bottom: 54px;
    background-color: rgba(255, 255, 255, 0.21);

    @include is-screen-max($breakpoint-sm) {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .col {
        &:nth-child(2) {
            .footer__info {
                margin-left: 30px;

                @include is-screen-max($breakpoint-lg) {
                    margin-left: 0;
                }
            }
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        font-size: 15px;
        line-height: 17px;
        letter-spacing: 0.16px;
        color: rgba(255, 255, 255, 0.5);

        @include is-screen-max($breakpoint-sm) {
            align-items: center;
            margin-bottom: 20px;
            text-align: center;
        }

        a {
            color: inherit;
            transition: color 0.3s;

            &:hover {
                color: rgba(255, 255, 255, 1);
            }
        }
    }

    &__logo {
        display: flex;
        justify-content: flex-end;
        height: 45px;

        @include is-screen-max($breakpoint-sm) {
            justify-content: center;
            margin-top: 20px;
        }
    }
}
