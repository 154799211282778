.text-card {
    width: 100%;
    padding: 20px;
    border: 1px solid rgba(255, 255, 255, 0.24);
    border-radius: 8px;

    @include is-screen-max($breakpoint-sm) {
        border-radius: 6px;
    }

    &__title {
        font-family: "Technor", sans-serif;
        font-weight: 400;
        font-size: 20px;
        line-height: 120%;
        color: #fefefe;
        text-transform: uppercase;

        @include is-screen-max($breakpoint-sm) {
            font-size: 16px;
        }

        span {
            color: #0cc166;
        }
    }

    &__description {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: 15px;
        font-size: 16px;
        line-height: 140%;
        color: #d9d9d9;

        @include is-screen-max($breakpoint-sm) {
            gap: 10px;
            margin-top: 10px;
            font-size: 14px;
        }
    }
}
